.product-page {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  padding: 20px;
  margin-top: 70px;
}

.product-page img {
  max-width: 100%;
  height: 60%;
  border-radius: 8px;
}

.product-page .umkm-card:only-child {
  width: 300px;
}

.filter {
  margin-top: 20px;
  text-align: right;
}

.filter form {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align form items to the right on smaller screens */
  margin-right: 10px;
}

.filter label {
  margin-right: 10px;
}

.filter input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter input[type="text"]:focus {
  outline: none;
  border-color: #007bff;
}

.filter input[type="text"]::placeholder {
  color: #aaa;
}

.filter input[type="text"],
.filter button {
  margin-right: 10px;
}

.filter button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add transition effect on hover */
}

.filter button:hover {
  background-color: #0056b3;
}

/* Desktop Styles */
@media only screen and (min-width: 769px) {
  .product-page {
    height: 510px !important;
  }
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
  .product-page {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 10px;
  }

  .product-page img {
    height: auto; /* Reset the image height for better responsiveness */
  }

  .filter {
    margin-top: 10px;
  }

  .filter form {
    justify-content: center;
  }
}
