.umkm-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  height: 90%;
}

.umkm-card:hover {
  transform: translateY(-5px);
}

.umkm-card-content {
  padding: 20px;
}

.umkm-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #103aad;
}

.umkm-card-description {
  font-size: 1rem;
  color: #666666;
  margin-bottom: 15px;
}

.umkm-card-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #fe9e0d !important;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.umkm-card-button:hover {
  background-color: #fe9e0d;
}

.umkm-card-button span {
  font-size: 16px;
  font-weight: 800;
}
