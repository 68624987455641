.umkm-page {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  margin-top: 70px;
}

.umkm-page img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.umkm-page .umkm-card:only-child {
  height: 540px;
  width: 390px;
}

.filter {
  float: right;
  margin-top: 20px;
}

.filter form {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.filter label {
  margin-right: 10px;
}

.filter input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter input[type="text"]:focus {
  outline: none;
  border-color: #007bff;
}

.filter input[type="text"]::placeholder {
  color: #aaa;
}

.filter input[type="text"],
.filter button {
  margin-right: 10px;
}

.filter button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add transition effect on hover */
}

.filter button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination button {
  padding: 5px 10px;
  border-color: #fe9e0d !important;
  background-color: #fe9e0d !important;
  cursor: pointer;
  color: #fff;
}

.pagination button.active, .pagination button:hover {
  background-color: #fe9e0d;
  border-color: #fe9e0d;
  color: #fff;
}


@media only screen and (max-width: 768px) {
  .umkm-page {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 10px;
  }
}
