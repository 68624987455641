.navbar-image {
  width: 50px;
}
.brand-navbar {
  font-size: 24px;
  font-weight: bold;
}
.brand-menu {
  font-size: 18px;
  color: #000;
  margin-right: 40px;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}

@media only screen and (max-width: 768px) {
  .brand-navbar {
    font-size: 18px;
  }
  .brand-menu {
    font-size: 14px;
    margin-right: 20px;
  }
  nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: auto;
  }
}